import {mapActions, mapGetters} from "vuex";

import productCard from "@/components/product-card/index.vue";

export default {
  name: "favorites",
  watch: {
    '$route'(newVal){
      if(newVal){
        this.getFavorites()
      }
    }
  },
  components: {
    productCard,
  },
  data(){
    return{

    }
  },
  created() {
    this.getFavorites()
  },
  computed:{
    ...mapGetters({
      favorites: 'favorites/favorites'
    }),

  },
  methods:{
    ...mapActions({
      getFavorites: 'favorites/GET_FAVORITES',
    }),
  }
}